<template>
  <div class="relative m-auto overflow-hidden">
    <ImageRenderer
      v-if="image"
      :alt="image?.alt"
      class="flex justify-center"
      :height="image?.height"
      :height-mobile="image?.heightMobile"
      :loading="Loading.Eager"
      :src="image?.src"
      :src-mobile="image?.srcMobile"
      :width="image?.width"
      :width-mobile="image?.widthMobile"
    />

    <div
      v-if="hasGradient"
      class="from-bg-surface-default-low mood-bush absolute -left-96 top-0 size-full bg-gradient-to-r to-transparent sm:-left-[25rem]"
      data-test="hero-banner-overlay"
    />

    <RevContainer>
      <div
        class="grid-standard absolute bottom-24 sm:bottom-12 md:bottom-40 md:w-full"
      >
        <div class="md:col-span-2 lg:col-span-6">
          <slot />
        </div>
      </div>
    </RevContainer>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { BannerTextColor } from '@backmarket/http-api/src/api-specs-content/models/banner'
import type { HeroProps } from '@backmarket/http-api/src/api-specs-content/models/hero'
import { RevContainer } from '@ds/components/Container'

import { Loading } from '../../constants/image-renderer.constants'
import ImageRenderer from '../../shared-components/ImageRenderer/ImageRenderer.vue'

export type HeroBannerProps = HeroProps

const props = withDefaults(defineProps<HeroBannerProps>(), {
  image: undefined,
  textColor: BannerTextColor.Black,
})

// This event is common to every CMS block in order to hide the block containers
// on demand
defineEmits(['error'])

const hasGradient = computed(() => {
  return props.textColor === BannerTextColor.White
})
</script>
