<template>
  <div>
    <BlockTitle :subtitle="subtitle" :title="title" />

    <component
      :is="tag"
      :rel="link?.rel"
      :target="link?.target"
      :to="link?.href"
      @click="sendTrackingData"
    >
      <ImageRenderer
        v-if="image"
        :alt="image?.alt"
        :height="image?.height"
        :height-mobile="image?.heightMobile"
        :media-max-width="MediaMaxWidth.SM"
        :src="image?.src"
        :src-mobile="image?.srcMobile"
        :width="image?.width"
        :width-mobile="image?.widthMobile"
      />
    </component>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type { MediaProps } from '@backmarket/http-api/src/api-specs-content/models/media'
import BlockTitle from '@backmarket/nuxt-layer-cms/BlockTitle.vue'
import type { ContentBlockProps } from '@backmarket/nuxt-layer-cms/models/content-block'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevButtonBase } from '@ds/components/ButtonBase'

import { MediaMaxWidth } from '../../constants/image-renderer.constants'
import ImageRenderer from '../../shared-components/ImageRenderer/ImageRenderer.vue'

export type MediaBlockProps = MediaProps & ContentBlockProps

const props = withDefaults(defineProps<MediaBlockProps>(), {
  tracking: () => ({}),
  image: undefined,
})

// This event is common to every CMS block in order to hide the block containers
// on demand
defineEmits(['error'])

const { trackClick } = useTracking()

const hasLink = computed(() => !isEmpty(props.link))
const tag = computed(() => (hasLink.value ? RevButtonBase : 'div'))

function sendTrackingData() {
  if (hasLink.value) {
    trackClick({
      ...props.tracking,
      ...{ name: props.image?.alt ?? {} },
    })
  }
}
</script>
