<template>
  <picture>
    <RevIllustrationSource
      v-if="srcMobile && heightMobile && widthMobile"
      :height="heightMobile"
      :media="mediaMaxWidth"
      :src="srcMobile"
      :width="widthMobile"
    />

    <RevIllustration
      :alt="alt"
      class="block w-full object-cover"
      :height="desktopHeight"
      :loading="loading"
      :src="src"
      :width="desktopWidth"
    />
  </picture>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import type { Image } from '@backmarket/http-api/src/api-specs-content/models/image'
import { RevIllustration } from '@ds/components/Illustration'
import { RevIllustrationSource } from '@ds/components/IllustrationSource'

import {
  Loading,
  MediaMaxWidth,
} from '../../constants/image-renderer.constants'

type ImageRendererProps = Image & {
  mediaMaxWidth?: MediaMaxWidth
  loading?: Loading
}

const props = withDefaults(defineProps<ImageRendererProps>(), {
  alt: '',
  mediaMaxWidth: MediaMaxWidth.SM,
  loading: Loading.Eager,
})

const desktopWidth = computed(() => props.width / 2)
const desktopHeight = computed(() => props.height / 2)
</script>
